import React, { useState} from 'react';
import './App.css';
import AboutUs from './AboutUs';
import logo from './assests/logo.jpeg';
import Footer from './footer';
import Vision from './Vision';
import Aim from './Aim';
import Service from './Service';
import Home from './Home';
import Gallery from './Gallery';
import OurTeams from './OurTeams';
import ContactUs from './ContactUs';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import emailjs from 'emailjs-com';

const Navbar = ({ onAboutClick, onVisionClick, onAimClick, onOurTeamClick, onServiceClick, onHomeClick, onGalleryClick, onContactUsClick }) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [modal, setModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_e3ds2fn', 'template_ixuyjn8', e.target, '4FL0jTsMaLHCJoX60')
      .then((result) => {
        console.log(result.text);
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000); // Automatically close after 1 second
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
    setModal(false);
  };

  return (
    <div className={`navbar ${isNavExpanded ? "active" : ""}`}>
      <div className="logo">
        <img src={logo} alt="Logo" onClick={() => onHomeClick(true)} />
      </div>
      <div className="hamburger-menu" onClick={() => setIsNavExpanded(!isNavExpanded)}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="nav-items">
        <a href="#home" onClick={() => { onHomeClick(true); setIsNavExpanded(false); }}>Home</a>
        <a href="#aboutus" onClick={() => { onAboutClick(true); setIsNavExpanded(false); }}>About Us</a>
        <a href="#ourteams" onClick={() => { onOurTeamClick(true); setIsNavExpanded(false); }}>Our Team</a>
        <a href="#services" onClick={() => { onServiceClick(true); setIsNavExpanded(false); }}>Services</a>
        <a href="#aim" onClick={() => { onAimClick(true); setIsNavExpanded(false); }}>Aim</a>
        <a href="#vision" onClick={() => { onVisionClick(true); setIsNavExpanded(false); }}>Vision</a>
        <a href="#gallery" onClick={() => { onGalleryClick(true); setIsNavExpanded(false); }}>Gallery</a>
        <Modal
          size='lg'
          isOpen={modal}
          toggle={() => setModal(!modal)}
        >
          <ModalHeader
            toggle={() => setModal(!modal)}
          >
            Contact us
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col lg={12}>
                  <div>
                    <label htmlFor='name'>
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className='form-control'
                      placeholder='Enter Name'></input>
                  </div>
                </Col>
                <Col lg={12}>
                  <div>
                    <label htmlFor='Email-Address'>
                      Email Address
                    </label>
                    <input
                      type="text"
                      name="email"
                      className='form-control'
                      placeholder='Enter Email Address'></input>
                  </div>
                </Col>
                <Col lg={12}>
                  <div>
                    <label htmlFor='Phone-number'>
                      Phone Number
                    </label>
                    <input
                      type="number"
                      name="phone"
                      className='form-control'
                      placeholder='Enter phone Number'></input>
                  </div>
                </Col>
                <Col lg={12}>
                  <div>
                    <label htmlFor='Questions'>
                      Questions
                    </label>
                    <input
                      type="text"
                      name="questions"
                      className='form-control'
                      placeholder='Enter Your question'></input>

                  </div>
                </Col>
              </Row>
              <br></br>
              <button className="free-consultation-btn" type="submit">
                Submit
              </button>
            </form>
          </ModalBody>
        </Modal>
        <button className="free-consultation-btn" onClick={() => setModal(true)}> Contact us </button>
      </div>

      {/* Success message popup */}
      <Modal
        isOpen={showSuccessMessage}
        toggle={() => setShowSuccessMessage(false)}
      >
        <ModalHeader toggle={() => setShowSuccessMessage(false)}>Success</ModalHeader>
        <ModalBody>
          Your message has been sent successfully!
        </ModalBody>
      </Modal>
    </div>
  );
};

const App = () => {
  const [showAboutUs, setShowAboutUs] = useState(false);
  const [showVision, setShowVision] = useState(false);
  const [showAim, setShowAim] = useState(false);
  const [showService, setShowServices] = useState(false);
  const [showHome, setShowHome] = useState(false);
  const [showClients, setShowClients] = useState(false);
  const [showOurTeams, setShowOurTeams] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);

  const handleAboutClick = () => {
    setShowAboutUs(true);
    setShowVision(false);
  };

  const handleVisionClick = () => {
    setShowVision(true);
    setShowAboutUs(false);
    setShowAim(false);
  };

  const handleAimClick = () => {
    setShowAim(true);
    setShowAboutUs(false);
    setShowVision(false);
  };

  const handleServiceClick = () => {
    setShowServices(true);
    setShowAim(false);
    setShowAboutUs(false);
    setShowVision(false);
  };

  const handleHomeClick = () => {
    setShowHome(true);
    setShowServices(false);
    setShowAim(false);
    setShowAboutUs(false);
    setShowVision(false);
  };

  const handleClientsClick = () => {
    setShowClients(true);
    setShowHome(false);
    setShowServices(false);
    setShowAim(false);
    setShowAboutUs(false);
    setShowVision(false);
  };

  const handleOurTeamsClick = () => {
    setShowOurTeams(true);
    setShowClients(false);
    setShowHome(false);
    setShowServices(false);
    setShowAim(false);
    setShowAboutUs(false);
    setShowVision(false);
  };

  const handleContactUsClick = () => {
    setShowContactUs(true);
    setShowOurTeams(false);
    setShowClients(false);
    setShowHome(false);
    setShowServices(false);
    setShowAim(false);
    setShowAboutUs(false);
    setShowVision(false);
  };

  return (
    <div className="app-container">
      <Navbar onAboutClick={handleAboutClick} onVisionClick={handleVisionClick} onAimClick={handleAimClick} onOurTeamClick={handleOurTeamsClick} onServiceClick={handleServiceClick} onHomeClick={handleHomeClick} onGalleryClick={handleClientsClick} onContactUsClick={handleContactUsClick} />
      {showAboutUs ? (
        <AboutUs />
      ) : showVision ? (
        <Vision />
      ) : showAim ? (
        <Aim />
      ) : showService ? (
        <Service />
      ) : showHome ? (
        <Home />
      ) : showClients ? (
        <Gallery />
      ) : showOurTeams ? (
        <OurTeams />
      ) : showContactUs ? (
        <ContactUs />
      ) : (
        <>
          <Home />
        </>
      )}
      <Footer />
    </div>
  );
};

export default App;
