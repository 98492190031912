import React from "react";
import "./Vision.css";
import visionImage from "./assests/vision.jpg";

import { Container, Typography, Grid, Paper, Divider } from "@mui/material";
import { useSpring, animated } from "react-spring";


const Vision = () => {
  const animationProps = useSpring({
    from: { opacity: 0, transform: "translateY(50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { duration: 1000 },
  });

  return (
    <div className="vision-container">
      <animated.div style={animationProps} className="vision-content">
        <Container maxWidth="xl">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Paper elevation={3} className="vision-paper">
                <Typography variant="h4" className="vision-title">
                  <span className="vision-title-bold">Vision</span>
                </Typography>
                <Divider className="separator" />
                <Typography variant="body1" className="vision-content">
                Medhkara's vision with values encompasses the company's aspirations to
                provide information and guidance for further stepping up of our
                customer's businesses and to become a globally admired company.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <img src={visionImage} alt="Vision" className="vision-image" />
            </Grid>
          </Grid>
        </Container>
      </animated.div>
    </div>
  );
};

export default Vision;