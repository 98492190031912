// Aim.js
import React from "react";
import "./Aim.css";
import aim from "./assests/aim.jpg";
import { Container, Typography, Grid, Paper, Divider } from "@mui/material";
import { useSpring, animated } from "react-spring";


const Aim = () => {
  const animationProps = useSpring({
    from: { opacity: 0, transform: "translateY(50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { duration: 1000 },
  });

  return (
    <div className="aim-container">
      <animated.div style={animationProps} className="aim-content">
        <Container maxWidth="xl">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Paper elevation={3} className="aim-paper">
                <Typography variant="h4" className="aim-title">
                  <span className="aim-title-bold">Aim</span>
                </Typography>
                <Divider className="separator" />
                <Typography variant="body1" className="aim-content">
                The Medhkara Consultancy is an emerging firm with the aim to
                provide qualitative services in the fields of Aviation,
                Defence, Human Resource Management, and Education. We offer a
                platform where tailor-made solutions by a team of visionary
                people, acting as consultants and advisors, lead to
                unprecedented growth..
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <img src={aim} alt="Aim" className="aim-image" />
            </Grid>
          </Grid>
        </Container>
      </animated.div>
    </div>
  );
};

export default Aim;