import React from "react";
import { useSpring, animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Sanjeev from "./assests/Sanjeev.jpg";
import Anju from "./assests/Anju.jpg";
import "./OurTeams.css";

const OurTeams = () => {
  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
  });

  return (
    <div className="container teamsBody">
      <animated.div className="ourteams-container" style={fadeIn}>
        <div className="header">
          <img className="profile" src={Sanjeev} alt="Profile Pic" />
          <div>
            <h4 className="ourteams-title">
              Dr Sanjeev Kapoor, Ati Vishisht Seva Medal, Vayu Sena Medal
              (Retd), M.Sc (Defence Studies), MMS, M. Phil and PhD
            </h4>
            <p className="designation">Director General</p>
          </div>
        </div>
        <hr />
        <h3 className="resume">RESUME</h3>
        <p className="teamAbout">
          Dr Sanjeev Kapoor has served for nearly four decades in IAF and has
          vast experience of handling administration matters which in turn has
          led to improved operational efficiency, cost cutting, improved supply
          chain management and logistics planning. He has also handled HR
          management of complete IAF wherein he handled induction, career
          planning and progression of Air warriors. He was instrumental in
          policy formation for improving the work efficiency and productivity
          of the all personnel. Dr Kapoor's illustrious career within the
          Indian Air Force (IAF), a testament to his unwavering commitment and
          exceptional leadership. His significant impact is evident in his
          multifaceted roles, primarily focused on administration, where his
          strategic acumen has consistently fostered improved operational
          efficiency, cost-cutting initiatives, and streamlined supply chain
          management. A pivotal figure in HR management within the IAF, Dr
          Kapoor spearheaded the induction, career planning, and progression of
          Air warriors. His astute leadership played a crucial role in shaping
          the trajectory of personnel development, reflecting a deep commitment
          to nurturing talent and fostering a culture of excellence.
        </p>
      </animated.div>

      <animated.div className="ourteams-container" style={fadeIn}>
        <div className="header">
          <img className="profile" src={Anju} alt="Profile Pic" />
          <div>
            <h4 className="ourteams-title">Mrs Anju Kapoor, B.Tech, MBA</h4>
            <p className="designation">Human Resources Manager</p>
          </div>
        </div>
        <hr />
        <h3 className="resume">RESUME</h3>
        <p className="teamAbout">
          Mrs. Anju Kapoor brings a strong educational background with a B Tech,
          MBA, and a diploma in counseling, coupled with experience in both
          corporate and NGO settings. Together, Air Marshal Sanjeev Kapoor and
          Mrs. Anju Kapoor enrich Medhkara Consultancy LLP with a blend of
          strategic thinking, administrative prowess, and a commitment to
          organizational growth.
        </p>
      </animated.div>
    </div>
  );
};

export default OurTeams;
