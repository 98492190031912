import React, { useState, useEffect, useMemo } from 'react';
import Slider1 from './assests/s15.jpg';
import slider5 from './assests/s14.jpg';
import slider22 from './assests/s11.jpg';
import slide3 from './assests/s12.jpg';
import ser4 from './assests/s13.jpg';
import sanjeev from './assests/sanjeev.jpeg';
import './Home.css';

const ServiceCards = () => {
  return (
    <div className="service-cards">
      <div className="card">
        <h2>Owner's Message</h2>
        <img src={sanjeev} alt="sanjeev kapoor" height={450}/> {/* Increased height to 450px */}
        <h3>Dr. Sanjeev Kapoor, PhD</h3>
        <p>As the owner of our consultancy, I am deeply committed to guiding and empowering our clients in the realms of Aviation, Defence, Human Resource Management, and Education. Our mission is to transform challenges into opportunities, offering unparalleled expertise and strategic insights to propel your success to new heights.</p>
      </div>
    </div>
  );
};

const Home = () => {
  const [currentBackground, setCurrentBackground] = useState(Slider1);

  const sliders = useMemo(() => [Slider1, slider22, slide3, ser4, slider5], []);

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      setCurrentBackground(sliders[currentIndex]);
      currentIndex = (currentIndex + 1) % sliders.length;
    }, 5000); // Change background every 5 seconds
    return () => clearInterval(intervalId);
  }, [sliders]);

  return (
    <div className='Home'>
      <div className="hero-section" style={{ backgroundImage: `url(${currentBackground})`}}>
        <div className="hero-overlay">
          <div className="hero-text">
            <h1>Your success journey starts with us!</h1>
            <p className="eduplan">Medhkara Consultancy</p>
            <p>Elevate Your Success Skyward: Premier Consulting Services in Aviation, Defence, Human Resource Management, and Education. Unlock Strategic Excellence with Our Expert Advisors.</p>
          </div>
          <div className="pagination">
            {sliders.map((_, index) => (
              <span key={index} className={`dot ${index === sliders.indexOf(currentBackground) ? "active" : ""}`}></span>
            ))}
          </div>
        </div>
      </div>
      <div className='hero-cards'>
        <ServiceCards />
      </div>
    </div>
  );
};

export default Home;
