import React, { useState } from 'react';
import { useSpring, animated, config } from 'react-spring';
import img1 from './Kapoor Sir/sli3.jpg';
import img2 from './Kapoor Sir/slide3.jpg';
import img3 from './anju_mam/ser4.jpg'; // Import image from the new folder
import img4 from './anju_mam/sev3.jpg'; // Import image from the new folder
import './Gallery.css';

const GalleryPage = () => {
  // State to keep track of selected folder
  const [selectedFolder, setSelectedFolder] = useState(1); // Set default folder ID here

  // Animation for folder and image
  const folderAnimation = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: config.wobbly, // Adjust animation behavior
  });

  const imageAnimation = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: config.default, // Adjust animation behavior
  });

  // Sample data for folders and images
  const folders = [
    { id: 1, name: 'Dr. Sanjeev Kapoor', folderName: 'Dr. Sanjeev Kapoor', images: [img1, img2] },
    { id: 2, name: 'Mrs Anju Kapoor', folderName: 'Mrs Anju Kapoor', images: [img3, img4] } // Add new folder
  ];

  // Function to handle folder selection
  const handleFolderSelect = (folderId) => {
    setSelectedFolder(folderId);
  };

  return (
    <div className="gallery-container">
      <animated.h1 className="gallery-title" style={folderAnimation}>Gallery</animated.h1>
      <div className="folders">
        {folders.map(folder => (
          <animated.div key={folder.id} className="folder" style={folderAnimation} onClick={() => handleFolderSelect(folder.id)}>
            {folder.name}
          </animated.div>
        ))}
      </div>
      <animated.div className="images" style={imageAnimation}>
        {selectedFolder !== null && (
          folders.find(folder => folder.id === selectedFolder).images.map(image => (
            <img key={image} src={image} alt={image} />
          ))
        )}
      </animated.div>
    </div>
  );
};

export default GalleryPage;
