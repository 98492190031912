// Assuming Vision.js
import React from "react";
import aviation from "./assests/aviation.jpeg";
import Armed_forces_logo from "./assests/D1.png";
import human_resource from "./assests/d5.jpg";
import education from "./assests/education.webp";
import "./Service.css";

const Service = () => {
  return (
    <div className="service-container">
      <h1 className="service-title">Our Services</h1>
      <hr className="divider"></hr>
      <p className="service-content">
        We provide consultancy and advisory services in Aviation, Defence, Human Resource Management, and Education fields.
      </p>
      <div className="expert-image">
        <div className="gallery">
          <img src={aviation} alt="Aviation" />
          <div className="desc">
            <h3>Aviation</h3>
            <p>We provide consultancy in aviation, including manpower management and growth strategies.</p>
          </div>
        </div>
        <div className="gallery">
          <img src={Armed_forces_logo} alt="Armed Forces" />
          <div className="desc">
            <h3>Defence</h3>
            <p>Our consultancy services for defense focus on human resource management, financial planning, and aviation sector support.</p>
          </div>
        </div>
        <div className="gallery">
          <img src={human_resource} alt="Human Resource" />
          <div className="desc">
            <h3>Human Resource</h3>
            <p>Our human resource services enhance operational efficiency, supply chain management, and career progression.</p>
          </div>
        </div>
        <div className="gallery">
          <img src={education} alt="Education" />
          <div className="desc">
            <h3>Education</h3>
            <p>We offer educational consultancy to bridge the gap between education and employability.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;

