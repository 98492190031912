// AboutUs.js

import React from "react";
import "./AboutUs.css"; // Ensure you create this CSS file to style your components
import about from "./assests/about.jpeg";
import { Container, Typography, Grid, Paper, Divider } from "@mui/material";
import { useSpring, animated } from "react-spring";


const AboutUs = () => {
  const animationProps = useSpring({
    from: { opacity: 0, transform: "translateY(50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { duration: 1000 },
  });

  return (
    <div className="about-container">
      <animated.div style={animationProps} className="about-content">
        <Container maxWidth="xl">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Paper elevation={3} className="about-paper">
                <Typography variant="h4" className="about-title">
                  <span className="about-title-bold">About Us</span>
                </Typography>
                <Divider className="separator" />
                <Typography variant="body1" className="about-content">
                  Medhkara Consultancy LLP is a registered company based out of New
                  Delhi. It’s a joint venture of Dr. Sanjeev Kapoor and Mrs. Anju
                  Kapoor, who are both seasoned professionals in their respective
                  domains and bring a wealth of experience and expertise to the
                  organization. In the dynamic landscape of business, where
                  adaptability and strategic thinking are paramount, Medhkara emerges
                  as a beacon of experience and leadership. A proven track record
                  and commitment to excellence make us an invaluable addition to any
                  organization seeking to elevate its operations, enhance its work
                  culture, and navigate the complexities of today's competitive
                  landscape. Medhkara Consultancy LLP is honored to have Dr. Sanjeev
                  Kapoor as a distinguished partner, and we are confident that his
                  wealth of experience will contribute significantly to our shared
                  journey of success and growth.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <img src={about} alt="About Us" className="about-image" />
            </Grid>
          </Grid>
        </Container>
      </animated.div>
    </div>
  );
};

export default AboutUs;